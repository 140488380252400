

































































































































































import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';
import Twitch from '../../assets/js/twitch-embed-v1.js'

import Widget from '../../components/Widget/Widget.vue';

import { mapGetters } from 'vuex';

Vue.use(BootstrapVue);

export default Vue.extend({
    name: 'Chat',
    components: {
        Widget
    },
    data() {
        return {
            editModalTitle: '' as string,
            editModalLabel: '' as string,
            announcementModal: {
                id: null as number,
                text: '' as string,
                interval: null as number,
                enabled: null as number
            },
            player: null
        }
    },
    methods: {
        resetAnnouncementModal() {
            this.announcementModal.id = null;
            this.announcementModal.text = '';
            this.announcementModal.enabled = null;
            this.announcementModal.interval = null;
        },
        addAnnouncementModal(announcement: any) {
            this.$root.$emit('bv::show::modal', 'modal-add-announcement');
        },
        handleAddAnnouncement(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.addAnnouncement();
        },
        addAnnouncement() {
            // Exit when the form isn't valid
            if (!this.announcementState) {
                return
            }
            this.$socket.client.emit('add-announcement', this.announcementModal);
            this.$nextTick(() => {
                this.$root.$emit('bv::hide::modal', 'modal-add-announcement')
            })
        },
        editAnnouncementModal(announcement: any, title: string) {
            // handle dynamic text in modal
            this.editModalTitle = 'Edit ' + title;
            this.editModalLabel = title;
            this.announcementModal.id = announcement.id;
            this.announcementModal.text = announcement.text;
            this.announcementModal.interval = announcement.interval;
            this.announcementModal.enabled = announcement.enabled;
            this.$root.$emit('bv::show::modal', 'modal-edit-announcement')
        },
        handleEditAnnouncement(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.editAnnouncement();
        },
        editAnnouncement() {
            if (!this.announcementState) {
                console.log(`CLIENT: form isn't valid`);
                return
            }
            this.$socket.client.emit('edit-announcement', this.announcementModal);
            this.$nextTick(() => {
                this.$root.$emit('bv::hide::modal', 'modal-edit-announcement');
            })
        },
        deleteAnnouncementModal(announcement: any) {
            this.announcementModal.id = announcement.id;
            this.$root.$emit('bv::show::modal', 'modal-delete-announcement');
        },
        handleDeleteAnnouncement(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.deleteAnnouncement();
        },
        deleteAnnouncement() {
            this.$socket.client.emit('delete-announcement', this.announcementModal);
            this.$nextTick(() => {
                this.$root.$emit('bv::hide::modal', 'modal-delete-announcement');
            })
        },
        twitchChatInit() {
            // appears as an error because of the script import
            this.player = new Twitch.Player("twitch-embed", {
                width: 854,
                height: 480,
                channel: "zambies",
            })
            this.player.addEventListener(Twitch.Player.READY, () => {

            })
        }
    },
    computed: {
        ...mapGetters('chatAnnouncements', ['getChatAnnouncements']),
        theChatAnnouncements(this: any): any[] {
            return this.getChatAnnouncements;
        },
        announcementState(): boolean {
            return this.announcementModal.text.length > 0
                && this.announcementModal.interval >= 1
                && this.announcementModal.enabled !== null
                && [0, 1].includes(Number(this.announcementModal.enabled))
        },
        invalidFeedback(): string {
            if (this.announcementModal.text.length > 0
                    && this.announcementModal.interval >= 1
                    && this.announcementModal.enabled !== null
                    && [0, 1].includes(Number(this.announcementModal.enabled))) {
                return ''
            } else if (this.announcementModal.text.length === 0) {
                return 'Text cannot be empty'
            } else if (this.announcementModal.interval < 1) {
                return 'Interval cannot be less than 1'
            } else if (this.announcementModal.enabled === null
                    || (! [0,1].includes(Number(this.announcementModal.enabled)))) {
                return 'Please choose an \'Enabled\' option'
            } else {
                return 'The form data could not be validated'
            }
        },
        validFeedback(): string {
            return this.announcementState === true ? 'Thank you' : ''
        },
        twitchChatSrc(): string {
            // darkpopout == darkmode
            return 'https://www.twitch.tv/embed/zambies/chat?darkpopout&parent=dashboard.zteambot.com';
        }
    },
    mounted() {
        this.twitchChatInit();
    },
    sockets: {
        chatAnnouncements(fetchedData) {
            this.$store.dispatch('chatAnnouncements/setChatAnnouncements', fetchedData);
        }
    }
})

