const state = {
    viewerData: []
};

const getters = {
    getViewerData: state => state.viewerData,
};

const mutations = {
    SET_VIEWER_DATA(state, payload) {
        state.viewerData = payload;
    },
};

const actions = {
    setViewerData({ commit }, viewerData) {
        commit('SET_VIEWER_DATA', viewerData);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};