

import { Line, mixins } from 'vue-chartjs';

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    options: {
      type: Object,
      default: null
    }
  },
  mounted() {
    this.renderChart(this.chartData, this.options);
  },
  watch: {
    options () {
      this.$data._chart.destroy();
      this.renderChart(this.chartData, this.options);
    }
  }
}

