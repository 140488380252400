





















































































































































































































































































































import axios from 'axios';
import Vue from 'vue';
import VueEasyLightbox from 'vue-easy-lightbox';
import { BootstrapVue } from 'bootstrap-vue';

import Widget from '../../components/Widget/Widget.vue';

import { mapGetters } from 'vuex';

Vue.use(BootstrapVue);
Vue.use(VueEasyLightbox);

import io from 'socket.io-client';

export default Vue.extend({
  name: 'Commands',
  components: {
    Widget
  },
  data() {
    return {
      imgs: {
        visible: false as boolean,
        img: ''
      },
      commands: [] as Object[],
      editModalTitle: '' as string,
      editModalLabel: '' as string,
      modalCommand: {
        id: null as number,
        commandNames: [] as Array<string>,
        enabled: null as number,
        permission: null as number,
        responseId: null as number,
        response: '' as string,
        imageName: '' as string,
        audioName: '' as string,
        responseDuration: null as number,
        responseVisualDelay: null as number,
        responseAudioDelay: null as number,
        type: null as number
      },
      modalFiles: {
        image: null as File,
        audio: null as File
      },
      currentSort: 'commandNames',
      currentSortDir: 'asc',
      commandFilter: null
    }
  },
  created() {
  },
  methods: {
    getAuthToken(callback) {
      axios.post('https://dev-sfzvc1i6.us.auth0.com/oauth/token', {
        crossDomain: true,
        "client_id": process.env.AUTH0_CLIENT_ID,
        "client_secret": process.env.AUTH0_CLIENT_SECRET,
        "audience": "https://dashboard.zteambot.com",
        "grant_type": "client_credentials"
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res  => {
        callback(null, res)
      }).catch((error) => {
        console.error(error);
        callback(error, null);
      })
    },
    resetCommandModal() {
      this.modalCommand.id = null;
      this.modalCommand.commandNames.splice(0, this.modalCommand.commandNames.length);
      this.modalCommand.enabled = null;
      this.modalCommand.permission = null;
      this.modalCommand.responseId = null;
      this.modalCommand.response = '';
      this.modalCommand.imageName = '';
      this.modalCommand.audioName = '';
      this.modalCommand.responseDuration = null;
      this.modalCommand.responseVisualDelay = null;
      this.modalCommand.responseAudioDelay = null;
      this.modalCommand.type = null;
      this.modalFiles.image = null;
      this.modalFiles.audio = null;
    },
    addCommandModal(command) {
      this.$root.$emit('bv::show::modal', 'modal-add-command');
    },
    handleAddCommand(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.addCommand();
    },
    addCommand() {
      // Exit when the form isn't valid
      if (!this.commandState) {
        return
      }
      if (this.modalFiles.image || this.modalFiles.audio) {
        this.submitFiles();
      }
      this.$socket.client.emit('add-command', this.modalCommand);
      this.$nextTick(() => {
        this.$root.$emit('bv::hide::modal', 'modal-add-command');
      })
    },
    editCommandModal(command, title) {
      // handle dynamic text in modal
      this.editModalTitle = 'Edit ' + title;
      this.editModalLabel = title;
      // handle modalCommand object population
      this.modalCommand.id = command.id;
      command.commandNames.forEach((theCommand) => {
        this.modalCommand.commandNames.push(theCommand.name);
      })
      this.modalCommand.enabled = command.enabled;
      this.modalCommand.permission = command.permission;

      if (title === 'Action') {
        this.modalCommand.type = 2;
      } else if (title === 'Command') {
        this.modalCommand.type = 1;
      }

      this.modalCommand.responseId = command.commandResponses[0].id;
      this.modalCommand.response = command.commandResponses[0].response;

      let visual = command.commandResponses[0].responseVisual;
      let audio = command.commandResponses[0].responseAudio;
      if (visual) {
        this.modalCommand.imageName = visual.substring(visual.lastIndexOf('/')+1, visual.length);
      }
      if(audio) {
        this.modalCommand.audioName = audio.substring(audio.lastIndexOf('/')+1, audio.length);
      }
      this.modalCommand.responseDuration = command.commandResponses[0].responseDuration;
      this.modalCommand.responseVisualDelay = command.commandResponses[0].responseVisualDelay;
      this.modalCommand.responseAudioDelay = command.commandResponses[0].responseAudioDelay;
      this.$root.$emit('bv::show::modal', 'modal-edit-command');
    },
    handleEditCommand(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.editCommand();
    },
    editCommand() {
      // Exit when the form isn't valid
      if (!this.commandState) {
        console.log(`CLIENT: form isn't valid`);
        return
      }
      if (this.modalFiles.image || this.modalFiles.audio) {
        console.log(`CLIENT: submitting files`);
        this.submitFiles();
      }
      console.log(`CLIENT: emitting edit, ${JSON.stringify(this.modalCommand, null, 2)}`);
      this.$socket.client.emit('edit-command', this.modalCommand);
      this.$nextTick(() => {
        this.$root.$emit('bv::hide::modal', 'modal-edit-command');
      })
    },
    deleteCommandModal(command) {
      this.modalCommand.id = command.id;
      this.modalCommand.responseId = command.commandResponses[0].id;
      // to populate the delete modal 'command' entry
      command.commandNames.forEach((theCommand) => {
        this.modalCommand.commandNames.push(theCommand.name);
      })
      this.$root.$emit('bv::show::modal', 'modal-delete-command');
    },
    handleDeleteCommand(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.deleteCommand();
    },
    deleteCommand() {
      this.$socket.client.emit('delete-command', this.modalCommand);
      this.$nextTick(() => {
        this.$root.$emit('bv::hide::modal', 'modal-delete-command');
      })
    },
    playSound(sound) {
      if (sound) {
        var audio = new Audio(sound);
        audio.play();
      }
    },
    showLightboxImage(image) {
      this.imgs.img = image;
      this.imgs.visible = true;
    },
    hideLightboxImage() {
      this.imgs.visible = false;
    },
    submitFiles() {
      let formData = new FormData();
      if (this.modalFiles.image) {
        this.modalCommand.imageName = this.modalFiles.image.name;
        formData.append('image', this.modalFiles.image);
      }
      if (this.modalFiles.audio) {
        this.modalCommand.audioName = this.modalFiles.audio.name;
        formData.append('audio', this.modalFiles.audio);
      }
      this.getAuthToken((err, res) => {
        if (err) {
          console.error('Unable to get Auth token for /upload')
        }
        if (res) {
          axios.post('https://dashboard.zteambot.com/upload',
            formData,
            {
              headers: {
                'Authorization': `Bearer ${res.data.access_token}`,
                'Content-Type': 'multipart/form-data'
              }
            }
          ).then(() => {
            console.log('CLIENT: Upload success!');
          }).catch(err => {
            console.log(`CLIENT: ${err}`);
          })
        }
      })
    },
    sort(s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'asc' ? 'desc': 'asc';
      }
      this.currentSort = s;
    }
  },
  computed: {
    ...mapGetters('commands', [
      'getCommands',
    ]),
    // if return types aren't given here, will get 'resolve property does not exist' errors on build
    theCommands(this: any): any[] {
      return this.getCommands.filter(command => command.commandResponses !== undefined && command.commandResponses.length > 0
        && command.type.id == '1').sort((a,b) => {
          let modifier = 1;
          if (this.currentSortDir === 'desc') {
            modifier = -1;
          }
          if(a.commandNames[0]["name"] < b.commandNames[0]["name"]) {
            return -1 * modifier;
          }
          if(a.commandNames[0]["name"] > b.commandNames[0]["name"]) {
            return 1 * modifier;
          }
          return 0;
        });
    },
    filteredCommands(): any[] {
      return this.commandFilter ? this.theCommands.filter(command => {
                                    return command.commandNames.map(c => {
                                      return c.name;
                                    }).join(",").toLowerCase().includes(this.commandFilter.toLowerCase()) })
                                  : this.theCommands
    },
    theActions(this: any): any[] {
      return this.getCommands.filter(command => command.commandResponses !== undefined && command.commandResponses.length > 0
        && command.type.id == '2');
    },
    commandState(): boolean {
      return this.modalCommand.commandNames.length > 0
        && this.modalCommand.enabled !== null
        && [0, 1].includes(Number(this.modalCommand.enabled))
        && this.modalCommand.permission !== null
        && [1, 2, 4].includes(Number(this.modalCommand.permission))
        && (this.modalCommand.responseDuration >= 0
          && this.modalCommand.responseDuration <= 120)
        && (this.modalCommand.responseVisualDelay >= 0
          && this.modalCommand.responseDuration <= 120)
        && (this.modalCommand.responseAudioDelay >= 0
          && this.modalCommand.responseAudioDelay <= 120)
    },
    invalidFeedback(): string {
      if (this.modalCommand.commandNames.length > 0
          && this.modalCommand.enabled !== null
          && [0, 1].includes(Number(this.modalCommand.enabled))
          && this.modalCommand.permission !== null
          && [1, 2, 4].includes(Number(this.modalCommand.permission))
          && (this.modalCommand.responseDuration >= 0
            && this.modalCommand.responseDuration <= 120)
          && (this.modalCommand.responseVisualDelay >= 0
            && this.modalCommand.responseDuration <= 120)
          && (this.modalCommand.responseAudioDelay >= 0
            && this.modalCommand.responseAudioDelay <= 120)) {
        return ''
      } else if (this.modalCommand.commandNames.length === 0) {
        return 'Command name cannot be empty'
      } else if (this.modalCommand.enabled === null
          || (! [0, 1].includes(Number(this.modalCommand.enabled)))) {
        return 'Please choose an \'Enabled\' option'
      } else if (this.modalCommand.permission === null
          || (! [1, 2, 4].includes(Number(this.modalCommand.permission)))) {
        return 'Please choose a \'Permission\' option'
      } else if (this.modalCommand.responseDuration < 0
          || this.modalCommand.responseVisualDelay < 0
          || this.modalCommand.responseAudioDelay < 0) {
        return 'Durations cannot be less than 0'
      } else if (this.modalCommand.responseDuration > 120
          || this.modalCommand.responseVisualDelay > 120
          || this.modalCommand.responseAudioDelay > 120) {
        return 'Durations cannot be greater than 120'
      } else {
        return 'The form data could not be validated'
      }
    },
    validFeedback(): string {
      return this.commandState === true ? 'Thank you' : ''
    },
    currCommandImageName(): string {
      return this.modalFiles.image ? this.modalFiles.image.name : this.modalCommand.imageName;
    },
    currCommandAudioName(): string {
      return this.modalFiles.audio ? this.modalFiles.audio.name : this.modalCommand.audioName;
    }
  },
  sockets: {
    commands(fetchedData) {
      this.$store.dispatch('commands/setCommands', fetchedData);
    }
  }
})

