const state = {
    broadcasterStats: []
};

const getters = {
  getBroadcasterStats: state => state.broadcasterStats
};

const actions = {
  setBroadcasterStats({ commit }, broadcasterStats) {
    commit('SET_BROADCASTER_STATS', broadcasterStats);
  },
};

const mutations = {
  SET_BROADCASTER_STATS(state, payload) {
    state.broadcasterStats = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};