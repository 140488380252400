import Vue from 'vue';
import VueSocketIOExt from 'vue-socket.io-extended';
import SocketIO from 'socket.io-client';
import VueTouch from 'vue-touch';

import App from './App.vue';
import router from './routes';
import store from './store';

import layoutMixin from './mixins/layout';
import Widget from './components/Widget/Widget.vue';

Vue.component('Widget', Widget);
Vue.mixin(layoutMixin);
Vue.use(VueSocketIOExt,
    SocketIO('https://dashboard.zteambot.com', {'reconnection': false}),
    { store }
);
Vue.use(VueTouch);

Vue.config.productionTip = false;
Vue.config.devtools = true;

// Ensure User Authorized & Streamer details are valid
router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresStreamerValidation)) {
        // this route requires an authorized user, check if it's true
        // if not, redirect to login page
        if (!store.getters.isAuthorizedUser) {
            next({ path: '' })
        // this route requires streamer data, check if it's true
        // if not, redirect to landing page
        } else if (!store.getters.isValidatedStreamer) {
            next({ path: 'landing' })
        } else {
            next() // go to wherever I'm going
        }
    } else {
        next() // does not require auth, make sure to always call next()!
    }
});

/* eslint-disable no-new */
new Vue({
    //e1: '#app',
    store,
    router,
    render: h => h(App),
}).$mount('#app')
