//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Loader from '../Loader/Loader';

export default {
  name: 'Widget',
  data: function() {
    return {
      state: this.collapsed ? 'collapse' : 'default'
    }
  },
  props: {
    customHeader: { type: Boolean, default: false },
    filter: { default: '' },
    filterValue: { default: '' },
    tooltipPlacement: { default: 'top' },
    showTooltip: { type: Boolean, default: false },
    add: { type: [Boolean, String], default: false },
    close: { type: [Boolean, String], default: false },
    fullscreen: { type: [Boolean, String], default: false },
    collapse: { type: [Boolean, String], default: false },
    settings: { type: [Boolean, String], default: false },
    settingsInverse: { type: Boolean, default: false },
    refresh: { type: [Boolean, String], default: false },
    className: { default: '' },
    title: { default: '' },
    customControls: { default: null },
    bodyClass: { default: '' },
    options: { default: () => ({}) },
    fetchingData: {type: Boolean, default: false},
    showLoader: {type: Boolean, default: true},
    collapsed: {type: Boolean, default: false},
    autoload: {type: [Boolean, Number], default: false}
  },
  components: { Loader },
  computed: {
    randomId() {
      return Math.floor(Math.random() * 100);
    },
    mainControls() {
      return !!(this.close || this.fullscreen || this.collapse
        || this.refresh || this.settings || this.settingsInverse);
    },
  },
  mounted() {
    if (this.autoload && this.$listeners && this.$listeners.load) {
      this.loadWidgster();
      if (typeof this.autoload === 'number') {
        setInterval(() => {this.loadWidgster()}, this.autoload);
      }
    }
    if (this.customControls) {
      let close = this.$refs.customControlsRef.querySelector('[control=close]');
      close && close.addEventListener('click', this.closeWidget);
      let collapse = this.$refs.customControlsRef.querySelector('[control=collapse]');
      collapse && collapse.addEventListener('click', this.changeState.bind(this, null, 'collapse'));
      let expand = this.$refs.customControlsRef.querySelector('[control=expand]');
      expand && expand.addEventListener('click', this.changeState.bind(this, null, 'default'));
      let fullscreen = this.$refs.customControlsRef.querySelector('[control=fullscreen]');
      fullscreen && fullscreen.addEventListener('click', this.changeState.bind(this, null, 'fullscreen'));
      let restore = this.$refs.customControlsRef.querySelector('[control=restore]');
      restore && restore.addEventListener('click', this.changeState.bind(this, null, 'default'));
      let load = this.$refs.customControlsRef.querySelector('[control=load]');
      load && load.addEventListener('click', this.loadWidgster);
    }
  },
  methods: {
    closeWidget(e) {
      e && e.preventDefault();
      let $parentEl = this.$el.parentElement;
      let length = $parentEl.classList.length;
      let parentToRemove = false;
      for (let i = 0; i < length; i++) {
        if (/col.*/.test($parentEl.classList[i])) {
          parentToRemove = true;
          break;
        }
      }

      let removeFunction = () => {
        parentToRemove ? $parentEl.remove() : this.$el.remove();
      };

      if (this.$listeners && this.$listeners.close) {
        this.$listeners.close(removeFunction.bind(this));
      } else {
        removeFunction();
      }
    },
    clearFilter() {
      this.filterValue = '';
    },
    changeState(e, state) {
      e && e.preventDefault();
      this.state = state;
    },
    loadWidgster(e) {
      e && e.preventDefault();
      this.$emit('load');
    },
    addModal() {

    }
  },
  watch: {
    filterValue() {
      this.$emit('filterValue', this.filterValue);
    }
  }
};
