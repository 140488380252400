import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import layout from './layout';

// Dashboard modules
import activityStream from './modules/activityStream';
import broadcasterStats from './modules/broadcasterStats';
import chatAnnouncements from './modules/chatAnnouncements';
import commands from './modules/commands';
import mods from './modules/mods';
import quotes from './modules/quotes';
import streamData from './modules/streamData';
import viewerData from './modules/viewerData';

Vue.use(Vuex);

export default new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        streamerHandle: '',
        authorizedUser: false,
        validatedStreamer: false,
        landingPageOverride: false,
    },
    getters: {
        getStreamerHandle: state => state.streamerHandle,
        isAuthorizedUser: state => state.authorizedUser,
        isValidatedStreamer: state => state.validatedStreamer,
        isLandingPageOverride: state => state.landingPageOverride,
    },
    actions: {
        setStreamerHandle({ commit }, payload) {
            commit('SET_STREAMER_HANDLE', payload);
        },
        setAuthorizedUser({ commit }, payload) {
            commit('SET_AUTHORIZED_USER', payload);
        },
        setValidatedStreamer({ commit }, payload) {
            commit('SET_VALIDATED_STREAMER', payload);
        },
        setLandingPageOverride({ commit }, payload) {
            commit('SET_LANDING_PAGE_OVERRIDE', payload);
        },
    },
    mutations: {
        SET_STREAMER_HANDLE(state, payload) {
            state.streamerHandle = payload;
        },
        SET_AUTHORIZED_USER(state, payload) {
            state.authorizedUser = payload;
        },
        SET_VALIDATED_STREAMER(state, payload) {
            state.validatedStreamer = payload;
        },
        SET_LANDING_PAGE_OVERRIDE(state, payload) {
            state.landingPageOverride = payload;
        },
    },
    modules: {
        activityStream,
        broadcasterStats,
        chatAnnouncements,
        commands,
        layout,
        mods,
        quotes,
        streamData,
        viewerData,
    }
});

