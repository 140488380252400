const state = {
  mods: []
};

const getters = {
  getMods: state => state.mods
};

const actions = {
  setMods({ commit }, mods) {
    commit('SET_MODS', mods);
  },
};

const mutations = {
  SET_MODS(state, payload) {
    state.mods = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};