











import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';

Vue.use(BootstrapVue);

import io from "socket.io-client";
var socket = io("https://dashboard.zteambot.com", {"reconnection": false});

export default Vue.extend({
  data() {
    return {
      goalNum: 2000,
      currNum: 0,
      goalPercent: 0,
    };
  },
  created() {
    this.getCurrentFollowers()
  },
  methods: {
    getCurrentFollowers() {
      socket.on("stream-data-followers", fetchedData => {
        if (fetchedData != null) {
          this.currNum = fetchedData;
          this.calculateGoalPercentage();
        }
      })
    },
    calculateGoalPercentage() {
      this.goalPercent = 100 * (this.currNum / this.goalNum);
    }
  },
});

