//
//
//
//
//


export default {
  name: 'Loader',
  props: {
    size: {type: Number, default: 21}
  }
}
