const state = {
  quotes: []
};

const getters = {
  getQuotes: state => state.quotes
};

const actions = {
  setQuotes({ commit }, quotes) {
    commit('SET_QUOTES', quotes);
  },
};

const mutations = {
  SET_QUOTES(state, payload) {
    state.quotes = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};