























































































































































































































import axios from 'axios';
import Vue from 'vue';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import Widget from '../../components/Widget/Widget.vue';
//import Line from '../../components/Chart/Line.vue';

import { mapGetters, mapState } from 'vuex';

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

import io from "socket.io-client";

var socket = io("https://dashboard.zteambot.com", {"reconnection": false});

export default Vue.extend({
  name: 'Dashboard',
  components: {
    Widget,
    //Line
  },
  data() {
    return {
      commandList: [{ name: "Follower", action: "follower" },
        { name: "Raid", action: "raid" },
        { name: "Host", action: "host" },
        { name: "Lick", action: "lick" },
        { name: "Eyebrows", action: "eyebrow" },
        { name: "Coffee!", action: "coffee" },
        { name: "LOS", action: "los" },
        { name: "Nameslayer", action: "nameslayer" },
        { name: "Trap!", action: "trap" },
        { name: "Murica", action: "america" },
        { name: "Daddy", action: "daddy" },
        { name: "Surely", action: "surely" },
        { name: "Greetings!", action: "greetings" },
        { name: "Yawn", action: "yawn" },
        { name: "Duck", action: "duck" },
        { name: "Snack (Elf)", action: "elf" },
        { name: "Snack (Meatloaf)", action: "meatloaf" },
        { name: "Sad Trombone", action: "sad" },
        { name: "Squirrel", action: "squirrel" },
        { name: "Towel", action: "towel" },
        { name: "Pizza", action: "pizza" },
        { name: "Second Breakfast", action: "breakfast" },
        { name: "Potatoes", action: "potatoes" },
      ],
      // mods
      checkedMods: [],
      modName: "",
      modNameState: null,
      // quotes
      quotes: [],
      modalQuote: "",
      quoteFilter: null,
      currQuote: null,
      quoteState: null,
      quoteDisplayNum: 0,
      quoteDebounce: null as NodeJS.Timeout,
      zteambotForm: {
        message: ""
      },
      live: false,
      liveAt: "00:00",
      viewers: 0,
      viewersHist: [],
      followers: 0,
      subscribers: 0,
    };
  },
  created() {
    console.log("In created()");
    this.notifyServer();
    //this.$forceUpdate();
  },
  methods: {
    formatUptime(liveAt) {
      let milliseconds = new Date().valueOf() - new Date(liveAt).valueOf();
      let hours = Math.floor((milliseconds / (1000 * 60 * 60)) % 24);
      let minutes = Math.floor((milliseconds / (1000 * 60)) % 60);
      let seconds = Math.floor((milliseconds / 1000) % 60);
      this.liveAt = `${(hours < 10) ? "0" + hours : hours}:${(minutes < 10) ? "0" + minutes : minutes}:${(seconds < 10) ? "0" + seconds: seconds}`;
    },
    setStreamData(fetchedData) {
      fetchedData.viewers ? this.live = true: this.live = false;
      if (this.viewersHist.length === 25) {
        this.viewersHist.pop();
      }
      fetchedData.viewers ? this.viewersHist.push(fetchedData.viewers): this.viewersHist.push(0);
    },
    getViewersHistory() {
      return this.viewersHist;
    },
    resetEditQuoteModal() {
      this.currQuote = null;
      this.modalQuote = "";
      this.quoteState = "";
    },
    editQuoteModal(data) {
      this.currQuote = data;
      this.modalQuote = data.quote;
      this.$root.$emit("bv::show::modal", "modal-edit-quote");
    },
    handleEditQuote(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.editQuote();
    },
    editQuote() {
      this.currQuote.quote = this.modalQuote;
      this.$socket.client.emit("edit-quote", this.currQuote);
      this.$nextTick(() => {
        this.$root.$emit("bv::hide::modal", "modal-edit-quote");
      })
    },
    resetDeleteQuoteModal() {
      this.currQuote = null;
    },
    deleteQuoteModal(data) {
      this.currQuote = data;
      this.$root.$emit("bv::show::modal", "modal-delete-quote");
    },
    deleteQuote(quoteId) {
      this.$socket.client.emit("delete-quote", this.currQuote.id);
      this.$nextTick(() => {
        this.$root.$emit("bv::hide::modal", "modal-delete-quote");
      })
    },
    handleQuoteScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (this.quoteDebounce) clearTimeout(this.quoteDebounce);
      this.quoteDebounce = setTimeout(() => {
        let container = this.$el.querySelector("#quoteTable");
        if (scrollTop + clientHeight >= scrollHeight) {
          this.quoteDisplayNum = this.quoteDisplayNum += 15;
          container.scrollTop = container.clientHeight / 2;
        } else if(scrollTop === 0 && this.quoteDisplayNum >= 15) {
          this.quoteDisplayNum = this.quoteDisplayNum -= 15;
          container.scrollTop = 50;
        }
      }, 500);
    },
    deleteMods() {
      this.$socket.client.emit("delete-mods", this.checkedMods);
      this.$nextTick(() => {
        this.checkedMods = [];
      })
    },
    checkModAddFormValidity() {
      //const valid = this.$refs.form.checkValidity();
      //this.modNameState = valid;
      //return valid;
    },
    resetModAddModal() {
      this.modName = "";
      this.modNameState = null;
    },
    handleModAdd(bvModalEvt) {
      // prevent modal from closing
      bvModalEvt.preventDefault();
      // trigger add mod submit
      this.addMod();
    },
    addMod() {
      // Exit when the form isn't valid
      //if (!this.checkModAddFormValidity()) {
      //    return;
      //}
      this.$socket.client.emit("add-mod", this.modName);
      this.$nextTick(() => {
        this.$bvModal.hide('modal-add-mod')
      })
    },
    notifyServer() {
      this.$socket.client.emit("client-ready");
    },
    submitZteambotMessage(evt) {
      evt.preventDefault();
      this.$socket.client.emit("zteambot-message", this.zteambotForm.message);
    },
    triggerCommand(action) {
      axios.post('https://dashboard.zteambot.com/notification', {
        'notification': action
      })
    },
  },
  computed: {
    ...mapState(["visualOption"]),
    ...mapGetters('activityStream', ['getActivityStream']),
    ...mapGetters('broadcasterStats', ['getBroadcasterStats']),
    ...mapGetters('mods', ['getMods']),
    ...mapGetters('quotes', ['getQuotes']),
    ...mapGetters('streamData', ['getDashboardData']),
    // computed return type is required or error will be thrown that "this.quoteFilter" or "this.quotes" cannot be found
    activityStream(this: any): any[] {
      return this.getActivityStream;
    },
    broadcasterStats(this: any): any[] {
      return this.getBroadcasterStats;
    },
    filteredQuotes(this: any): any[] {
      return this.quoteFilter ? this.getQuotes.filter(entry => {
                                  return entry.id.toString() === this.quoteFilter
                                      || entry.quote.toLowerCase().includes(this.quoteFilter.toLowerCase())
                                      || entry.insertedBy.toLowerCase().includes(this.quoteFilter.toLowerCase()) })
                              : this.getQuotes.slice(this.quoteDisplayNum, this.quoteDisplayNum+15);
    },
    mods(this: any): any[] {
      return this.getMods;
    },
    streamData(this: any): any[] {
      return this.getDashboardData;
    }
  },
  mounted() {
    console.log("In mounted()");
  },
  sockets: {
    activityStream(fetchedData) {
      this.$store.dispatch('activityStream/setActivityStream', fetchedData);
    },
    broadcasterStats(fetchedData) {
      this.$store.dispatch('broadcasterStats/setBroadcasterStats', fetchedData);
    },
    commands(fetchedData) {
      this.$store.dispatch('commands/setCommands', fetchedData);
    },
    mods(fetchedData) {
      this.$store.dispatch('mods/setMods', fetchedData);
    },
    quotes(fetchedData) {
      this.$store.dispatch('quotes/setQuotes', fetchedData.reverse());
    },
    steamData(fetchedData) {
      this.$store.dispatch('streamData/setDashboardData', fetchedData);
    },
    viewerData(fetchedData) {
      this.$store.dispatch('viewerData/setViewerData', fetchedData);
    }
  }
});

