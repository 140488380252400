



































































































import Vue from 'vue';

import io from "socket.io-client";
var socket = io("https://dashboard.zteambot.com", {"reconnection": false});

export default Vue.extend({
    name: 'Helper',
    data() {
        return {
            opened: false,
            discordMessage: "@here Hello, Zombies. Zambies is live! https://www.twitch.tv/zambies",
            discordMessageState: null,
        }
    },
    methods: {
        toggle() {
            this.opened = !this.opened;
        },
        resetDiscordMessageModal() {
            this.discordMessage = "@here Hello, Zombies. Zambies is live! https://www.twitch.tv/zambies";
            this.discordMessageState = null;
        },
        triggerDiscordMessageModal(data) {
            this.$root.$emit("bv::show::modal", "modal-discord-message");
            if (typeof data === 'string') {
                this.discordMessage = data;
            }
        },
        handleDiscordMessage(bvModalEvt) {
            bvModalEvt.preventDefault();
            this.triggerDiscordMessage();
        },
        triggerDiscordMessage() {
           socket.emit("discord-notification", this.discordMessage);
           this.$nextTick(() => {
               this.$root.$emit("bv::hide::modal", "modal-discord-message");
           })
        }
    }
});

