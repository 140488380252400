



















































import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';

import { mapGetters } from 'vuex';

import Widget from '../../components/Widget/Widget.vue';

Vue.use(BootstrapVue);

export default Vue.extend({
    name: 'Viewers',
    components: {
        Widget,
    },
    data() {
        return {
            currentSort: 'commandNames',
            currentSortDir: 'asc'
        }
    },
    created() {

    },
    methods: {
        sort(s) {
            if (s === this.currentSort) {
                this.currentSortDir = this.currentSortDir === 'asc' ? 'desc': 'asc';
            }
            this.currentSort = s;
        }
    },
    computed: {
        ...mapGetters('viewerData', [
            'getViewerData'
        ]),
        viewerData(this: any): any[] {
            return this.getViewerData.sort((a,b) => {
                let modifier = 1;
                if (this.currentSortDir === 'desc') {
                    modifier = -1;
                }
                if (a[this.currentSort] < b[this.currentSort]) {
                    return -1 * modifier;
                }
                if (a[this.currentSort] > b[this.currentSort]) {
                    return 1 * modifier;
                }
                return;
            });
        },
    },
    mounted() {

    },
})

