const state = {
  chunks: [],
  data: [],
  dashboardData: []
};

const getters = {
  getChunks: state => state.chunks,
  getData: state => state.data,
  getDashboardData: state => state.dashboardData
};

const mutations = {
  SET_CHUNKS(state, payload) {
    state.chunks = payload;
  },
  SET_DATA(state, payload) {
    state.data = payload;
  },
  SET_DASHBOARD_DATA(state, payload) {
    state.dashboardData = payload;
  }
};

const actions = {
  /*socket_chartData({ dispatch, commit }, fetchedData) {
    commit('SET_DATA', fetchedData);
  },*/
  setChunks({ commit }, chunks) {
    commit('SET_CHUNKS', chunks);
  },
  setData({ commit }, data) {
    commit('SET_DATA', data);
  },
  setDashboardData({ commit }, dashboardData) {
    commit('SET_DASHBOARD_DATA', dashboardData);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};