//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('layout');

import Sidebar from '../Sidebar/Sidebar.vue';
import Header from '../Header/Header';
import Helper from '../Helper/Helper';
import BreadcrumbHistory from '../BreadcrumbHistory/BreadcrumbHistory.vue';

import './Layout.scss';

export default {
  name: 'Layout',
  components: { Sidebar, Header, Helper, BreadcrumbHistory },
  methods: {
    ...mapActions(['switchSidebar', 'handleSwipe', 'changeSidebarActive', 'toggleSidebar', 'swapVisuals'],
    ),
    handleWindowResize() {
      const width = window.innerWidth;

      if (width <= 768 && this.sidebarStatic) {
        this.toggleSidebar();
        this.changeSidebarActive(null);
      }
    },
    changeVisualMode() {

    }
  },
  computed: {
    ...mapState(["sidebarClose", "sidebarStatic", "visualOption"]),
  },
  created() {
    const staticSidebar = JSON.parse(localStorage.getItem('sidebarStatic'));

    if (staticSidebar) {
      this.$store.state.layout.sidebarStatic = true;
    } else if (!this.sidebarClose) {
      setTimeout(() => {
        this.switchSidebar(true);
        this.changeSidebarActive(null);
      }, 2500);
    }

    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
};
