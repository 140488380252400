















































import Vue from 'vue';

import axios from 'axios';
import { mapGetters } from 'vuex';

export default Vue.extend({
    name: 'Landing',
    components: {},
    data() {
      return {
        form: {
          handle: '',
          platform: ''
        },
        platforms: [{ text: 'Select One', value: null }, 'Twitch', 'YouTube', 'Facebook'],
        show: false,
        token: '',
      }
    },
    created() {
      if (this.landingPageOverride) {
        this.show = true;
      } else if(this.isValidatedStreamer) {
        this.$router.push('dashboard');
      } else {
        if (!this.streamerHandle || this.streamerHandle.length === 0) {
          this.getAuthToken((err, res) => {
            if (err) {
              console.error('Unable to get Auth token.')
            }
            if (res) {
              axios.get('https://dashboard.zteambot.com/streamer', {
                headers: {
                  'Authorization': `Bearer ${res.data.access_token}`
                }
              }).then(res => {
                if (res && [200, 201].includes(res.status) && res.data) {
                  let handle = res.data[0].username;
                  if (handle) {
                    Promise.all([
                      this.$store.dispatch('setStreamerHandle', handle),
                      this.$store.dispatch('setValidatedStreamer', true)
                    ]).then(() => {
                      this.$router.push('dashboard');
                    });
                  }
                } else {
                  this.show = true;
                }
              }).catch(err => {
                this.show = true;
              });
            }
          });
        }
      }
    },
    methods: {
      onSubmit(evt) {
        evt.preventDefault();
        this.$socket.client.emit('add-streamer', this.form);
        Promise.all([
          this.$store.dispatch('setStreamerHandle', this.form.handle),
          this.$store.dispatch('setValidatedStreamer', true),
          this.$store.dispatch('setLandingPageOverride', false),
        ]).then(() => {
          this.$router.push('dashboard')
        });
      },
      onReset(evt) {
        evt.preventDefault();
        this.form.handle = '';
        this.form.platform = null;
        // Trick to reset/clear native browser form validation state
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        })
      },
      getAuthToken(callback) {
        axios.post('https://dev-sfzvc1i6.us.auth0.com/oauth/token', {
          crossDomain: true,
          "client_id": process.env.AUTH0_CLIENT_ID,
          "client_secret": process.env.AUTH0_CLIENT_SECRET,
          "audience": "https://dashboard.zteambot.com",
          "grant_type": "client_credentials"
        }, {
          headers: {
            'Content-Type': 'application/json'
          }
        }).then(res  => {
          callback(null, res)
        }).catch((error) => {
          console.error(error);
          callback(error, null);
        })
      }
    },
    computed: {
      ...mapGetters({
        streamerHandle: 'getStreamerHandle',
        isValidatedStreamer: 'isValidatedStreamer',
        landingPageOverride: 'isLandingPageOverride',
      })
    }
})

