//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import LineGraph from '../../components/Graph/LineGraph.vue';

import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'LineGraphContainer',
  components: {
    LineGraph
  },
  data: () => ({
    loaded: false,
    streamDataChunks: [],
    selectedDataChunk: null,
    streamGraphData: {},
    streamGraphOptions: {},
    stats: {
      viewers: {
        maximum: 0,
        achievedDuration: null
      },
      hosts: {
        maximum: 0,
        achievedDuration: null
      }
    }
  }),
  methods: {
    msToHMS(ms) {
      // 1- Convert to seconds:
      let seconds = ms / 1000;
      // 2- Extract hours:
      let hours = Math.floor(seconds / 3600); // 3,600 seconds in 1 hour
      seconds = seconds % 3600; // seconds remaining after extracting hours
      // 3- Extract minutes:
      let minutes = Math.floor(seconds / 60); // 60 seconds in 1 minute
      // 4- Keep only seconds not extracted to minutes:
      seconds = seconds % 60;
      function addZero(num) {
        return num < 10 ? '0' + num.toString() : num;
      }
      return `${addZero(hours)}:${addZero(minutes)}:${addZero(seconds)}`;
    },
    getGraphOptions() {
      return {
        title: {
          display: true,
          text: ''
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true
            },
            gridLines: {
              display: true
            }
          }],
          xAxes: [{
            gridLines: {
              display: false
            }
          }]
        },
        legend: {
          display: true
        },
        responsive: true,
        maintainAspectRatio: false
      }
    },
    resetGraphOptions() {
      this.streamGraphOptions = {};
      this.streamGraphOptions = this.getGraphOptions();
    },
    setGraphTitle(titleDate, createNewDate = false) {
      this.resetGraphOptions();
      if (createNewDate) {
        let dateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' }
        titleDate = new Date(titleDate).toLocaleString('en-US', dateOptions);
      }
      this.streamGraphOptions.title.text = `Stream: ${titleDate}`;
    },
    resetGraphData() {
      this.streamGraphData = { datasets: [], labels: [] };
      this.streamGraphData.datasets.push({ label: 'Viewers',
          backgroundColor: 'rgba(63, 47, 145)', data: [] })
      /*this.streamGraphData.datasets.push({ label: 'Hosts',
          backgroundColor: 'rgba(9, 124, 65)', data: [] })*/
    },
    populateGraphData(chosenChunk = 0) {
      this.resetGraphData();
      let initialDate = null;
      for (const data of this.getData[chosenChunk]) {
        if (!initialDate) {
          initialDate = new Date(data.insertedDt);
          this.streamGraphData.labels.push('00:00:00');
        } else {
          let currDate = new Date(data.insertedDt);
          this.streamGraphData.labels.push(this.msToHMS(Math.abs(currDate - initialDate)));
        }
        this.streamGraphData.datasets[0].data.push(data.viewers);
        //this.streamGraphData.datasets[1].data.push(data.hosts);
      }
      this.prepareMaxStatsForChunk(chosenChunk);
    },
    handleChunkChange() {
      this.loaded = false;
      let index = 0;
      for (const data of this.getData) {
        if (data[0].id === this.selectedDataChunk.minId) {
          this.populateGraphData(index);
          this.setGraphTitle(this.selectedDataChunk.streamDate);
          this.prepareMaxStatsForChunk(index);
          break;
        }
        index = index+1;
      }
      this.loaded = true;
    },
    prepareMaxStatsForChunk(chosenChunk = 0) {
      let maxViewers = 0;
      let maxHosts = 0;
      for (const data of this.getData[chosenChunk]) {
        if (data.viewers > maxViewers) {
          maxViewers = data.viewers;
        }
        if (data.hosts > maxHosts) {
          maxHosts = data.hosts;
        }
      }
      this.stats.viewers.maximum = maxViewers;
      this.stats.hosts.maximum = maxHosts;
    },
    getAuthToken(callback) {
      axios.post('https://dev-sfzvc1i6.us.auth0.com/oauth/token', {
        crossDomain: true,
        "client_id": process.env.AUTH0_CLIENT_ID,
        "client_secret": process.env.AUTH0_CLIENT_SECRET,
        "audience": "https://dashboard.zteambot.com",
        "grant_type": "client_credentials"
      }, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then(res  => {
        callback(null, res)
      }).catch((error) => {
        console.error(error);
        callback(error, null);
      })
    }
  },
  computed: {
    ...mapGetters('streamData', [
      'getChunks', 'getData'
    ]),
  },
  mounted () {
    this.loaded = false;
    this.getAuthToken(async (err, res) => {
      if (err) {
        console.error('Unable to get Auth token.')
      }
      if (res) {
        await axios.get('https://dashboard.zteambot.com/streamData/chunks', {
          headers: {
            'Authorization': `Bearer ${res.data.access_token}`
          }
        }).then(res => {
            if (res && res.status === 200 && res.data) {
              return this.$store.dispatch('streamData/setChunks', res.data);
            }
          })
          .then(() => {
            this.streamDataChunks = this.getChunks;
            this.setGraphTitle(this.streamDataChunks[0].text, true);
          })
        await axios.get('https://dashboard.zteambot.com/streamData', {
          headers: {
            'Authorization': `Bearer ${res.data.access_token}`
          }
        }).then(res => {
            if (res && res.status === 200 && res.data) {
              return this.$store.dispatch('streamData/setData', res.data);
            }
          })
          .then(() => {
            this.populateGraphData();
          })
        this.loaded = true;
      }
    })
  }
}

