const state = {
    chatAnnouncements: []
};

const getters: any = {
    getChatAnnouncements: state => state.chatAnnouncements
};

const actions = {
    setChatAnnouncements({ commit }, chatAnnouncements) {
        commit('SET_CHAT_ANNOUNCEMENTS', chatAnnouncements);
    },
};

const mutations = {
    SET_CHAT_ANNOUNCEMENTS(state, payload) {
        state.chatAnnouncements = payload;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
