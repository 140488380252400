









































import axios from 'axios';
import Vue from 'vue';
import { BootstrapVue } from 'bootstrap-vue';

import Widget from '../../components/Widget/Widget.vue';

Vue.use(BootstrapVue);

export default Vue.extend({
    name: 'Commandboard',
    components: {
        Widget,
    },
    data() {
        return {
            commandList: [{ name: "Follower", action: "follower" },
                { name: "Raid", action: "raid" },
                { name: "Host", action: "host" },
                { name: "Lick", action: "lick" },
                { name: "Eyebrows", action: "eyebrow" },
                { name: "Coffee!", action: "coffee" },
                { name: "LOS", action: "los" },
                { name: "Nameslayer", action: "nameslayer" },
                { name: "Trap!", action: "trap" },
                { name: "Murica", action: "america" },
                { name: "Daddy", action: "daddy" },
                { name: "Surely", action: "surely" },
                { name: "Greetings!", action: "greetings" },
                { name: "Yawn", action: "yawn" },
                { name: "Duck", action: "duck" },
                { name: "Snack (Elf)", action: "elf" },
                { name: "Snack (Meatloaf)", action: "meatloaf" },
                { name: "Sad Trombone", action: "sad" },
                { name: "Squirrel", action: "squirrel" },
                { name: "Towel", action: "towel" },
                { name: "Pizza", action: "pizza" },
                { name: "Second Breakfast", action: "breakfast" },
                { name: "Potatoes", action: "potatoes" },
            ],
        }
    },
    created() {

    },
    methods: {
        triggerCommand(action) {
            axios.post('https://dashboard.zteambot.com', {
                'notification': action
            })
        },
    },
    mounted() {

    }
})

