




































import Vue from 'vue';

import io from "socket.io-client";
import Queue from "queue-fifo";

import Video from "./Video/Video.vue";

var socket = io("https://dashboard.zteambot.com", {"reconnection": false});
var queue = new Queue<any>();

export default Vue.extend({
  data() {
    return {
      uiImageHidden: true as boolean,
      uiImagePath: '' as string,
      uiAudio: null as HTMLAudioElement,
      moviePath: null as string,
      transpGif: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==',
      followerImageHidden: true,
      giftedSubImageHidden: true,
      subImageHidden: true,
      elfImageHidden: true,
      secondBreakfastImageHidden: true,
      potatoesImageHidden: true,
      meatloafImageHidden: true,
      changingPumpkinImageHidden: true,
      dancingPumpkinImageHidden: true,
      jasonImageHidden: true,
      pumpkinToBatImageHidden: true,
      ratOTreatImageHidden: true,
      skellingtonImageHidden: true,
      miniBobImageHidden: true,
      miniAutumnImageHidden: true,
      miniBustImageHidden: true,
      miniZibaImageHidden: true,
      miniChibiImageHidden: true,
      miniDurbobImageHidden: true,
      miniYodaImageHidden: true,
      queueProcessing: false,
      text: '' as string
    };
  },
  computed: {
    followerAudio() {
      return require('../assets/audio/follower.mp3');
    },
    giftedSubAudio() {
      return require('../assets/audio/gifted-sub.mp3');
    },
    subAudio() {
      return require('../assets/audio/sub.mp3');
    },
    tipAudio() {
      return require('../assets/audio/tip.mp3');
    },
    elfAudio() {
      return require('../assets/audio/elf.mp3');
    },
    meatloafAudio() {
      return require('../assets/audio/meatloaf.mp3');
    },
    secondBreakfastAudio() {
      return require('../assets/audio/second-breakfast.mp3');
    },
    potatoesAudio() {
      return require('../assets/audio/potatoes.mp3');
    },
  },
  created(this: any) {
    this.socketReceive(),
    this.checkQueue()
  },
  methods: {
    getUiImage(src) {
      return src;
    },
    getUiAudio(src) {
      return src;
    },
    processNotification(imgSrc: string, audioSrc: string, duration: number,
        visualDelay: number, audioDelay: number) {
      this.uiImagePath = this.getUiImage(imgSrc);
      //this.uiImageHidden = false;
      setTimeout(() => {
        if (audioSrc) {
          this.playAudio(audioSrc);
        }
      }, audioDelay*1000);
      setTimeout(() => {
        if (imgSrc && (imgSrc.endsWith("mov") || imgSrc.endsWith("mp4"))) {
          this.moviePath = this.uiImagePath;
        } else {
          this.uiImageHidden = false;
        }
      }, visualDelay*1000);
      setTimeout(() => {
        if (imgSrc && (imgSrc.endsWith("mov") || imgSrc.endsWith("mp4"))) {
          this.moviePath = null;
        } else {
          this.uiImageHidden = true;
        }
        if (audioSrc) {
          this.stopAudio(audioSrc);
        }
        this.queueProcessing = false;
        this.uiImagePath = "";
      }, duration*1000);
    },
    checkQueue() {
      setInterval(() => {
        if (!queue.isEmpty() && !this.queueProcessing) {
          this.queueProcessing = true;
          let notification = queue.peek();
          if (notification) {
            switch(notification.notification) {
              case ("ui"):
                this.processNotification(notification.imgSrc, notification.audioSrc,
                  notification.duration, notification.visualDelay, notification.audioDelay);
                break;
              case ("sub"):
                //this.processSub();
                break;
              case ("gifted-sub"):
                //this.processGiftedSub();
                break;
              case ("elf"):
                this.processElf();
                break;
              case ("meatloaf"):
                this.processMeatloaf();
                break;
              case ("minibob"):
                this.processMiniBob();
                break;
              case ("miniautumn"):
                this.processMiniAutumn();
                break;
              case ("minibust"):
                this.processMiniBust();
                break;
              case ("miniziba"):
                this.processMiniZiba();
                break;
              case ("minichibi"):
                this.processMiniChibi();
                break;
              case ("minidurbob"):
                this.processMiniDurbob();
                break;
              case ("miniyoda"):
                this.processMiniYoda();
                break;
              case ("breakfast"):
                this.processSecondBreakfast();
                break;
              case ("potatoes"):
                this.processPotatoes();
                break;
              case ("changing-pumpkin"):
                this.processChangingPumpkin();
                break;
              case ("dancing-pumpkin"):
                this.processDancingPumpkin();
                break;
              case ("jason"):
                this.processJason();
                break;
              case ("pumpkin-to-bat"):
                this.processPumpkinToBat();
                break;
              case ("rat-o-treat"):
                this.processRatOTreat();
                break;
              case ("skellington"):
                this.processSkellington();
                break;
              default:
                break;
            }
            queue.dequeue();
          }
        }
      }, 1000);
    },
    processElf() {
      this.playAudio(this.elfAudio);
      this.elfImageHidden = false;
      setTimeout(() => {
        this.elfImageHidden = true;
        this.queueProcessing = false;
      }, 8150);
    },
    processMeatloaf() {
      this.playAudio(this.meatloafAudio);
      this.meatloafImageHidden = false;
      setTimeout(() => {
        this.meatloafImageHidden = true;
        this.queueProcessing = false;
      }, 11500);
    },
    processSecondBreakfast() {
      this.playAudio(this.secondBreakfastAudio);
      this.secondBreakfastImageHidden = false;
      setTimeout(() => {
        this.secondBreakfastImageHidden = true;
        this.queueProcessing = false;
      }, 3750);
    },
    processPotatoes() {
      this.playAudio(this.potatoesAudio);
      this.potatoesImageHidden = false;
      setTimeout(() => {
        this.potatoesImageHidden = true;
        this.queueProcessing = false;
      }, 5000);
    },
    processChangingPumpkin() {
      this.changingPumpkinImageHidden = false;
      setTimeout(() => {
        this.changingPumpkinImageHidden = true;
        this.queueProcessing = false;
      }, 5000);
    },
    processDancingPumpkin() {
      this.dancingPumpkinImageHidden = false;
      setTimeout(() => {
        this.dancingPumpkinImageHidden = true;
        this.queueProcessing = false;
      }, 5000);
    },
    processJason() {
      this.jasonImageHidden = false;
      setTimeout(() => {
        this.jasonImageHidden = true;
        this.queueProcessing = false;
      }, 6500);
    },
    processPumpkinToBat() {
      this.pumpkinToBatImageHidden = false;
      setTimeout(() => {
        this.pumpkinToBatImageHidden = true;
        this.queueProcessing = false;
      }, 5000);
    },
    processRatOTreat() {
      this.ratOTreatImageHidden = false;
      setTimeout(() => {
        this.ratOTreatImageHidden = true;
        this.queueProcessing = false;
      }, 5000);
    },
    processSkellington() {
      this.skellingtonImageHidden = false;
      setTimeout(() => {
        this.skellingtonImageHidden = true;
        this.queueProcessing = false;
      }, 5000);
    },
    processMiniBob() {
      this.miniBobImageHidden = false;
      setTimeout(() => {
        this.miniBobImageHidden = true;
        this.queueProcessing = false;
      }, 10000);
    },
    processMiniAutumn() {
      this.miniAutumnImageHidden = false;
      setTimeout(() => {
        this.miniAutumnImageHidden = true;
        this.queueProcessing = false;
      }, 10000);
    },
    processMiniBust() {
      this.miniBustImageHidden = false;
      setTimeout(() => {
        this.miniBustImageHidden = true;
        this.queueProcessing = false;
      }, 10000);
    },
    processMiniZiba() {
      this.miniZibaImageHidden = false;
      setTimeout(() => {
        this.miniZibaImageHidden = true;
        this.queueProcessing = false;
      }, 10000);
    },
    processMiniChibi() {
      this.miniChibiImageHidden = false;
      setTimeout(() => {
        this.miniChibiImageHidden = true;
        this.queueProcessing = false;
      }, 10000);
    },
    processMiniDurbob() {
      this.miniDurbobImageHidden = false;
      setTimeout(() => {
        this.miniDurbobImageHidden = true;
        this.queueProcessing = false;
      }, 10000);
    },
    processMiniYoda() {
      this.miniYodaImageHidden = false;
      setTimeout(() => {
        this.miniYodaImageHidden = true;
        this.queueProcessing = false;
      }, 10000);
    },
    playAudio(sound) {
      if (sound) {
        this.uiAudio = new Audio(sound);
        this.uiAudio.play();
      }
    },
    stopAudio(sound) {
      if (sound) {
        this.uiAudio.pause();
        this.uiAudio.currentTime = 0;
      }
    },
    socketReceive() {
      socket.on("notification", notifData => {
        console.log(`CLIENT: Received socket notification - name: ${notifData.notification}, text: ${notifData.text}`);
        queue.enqueue({
          "notification": notifData.notification,
          "text": (notifData.text == undefined) ? "" : notifData.text
        });
      })
      socket.on("ui-notification", notifData => {
        let notification = notifData.notification;
        console.log(`CLIENT: Received UI socket notification - name: ${notification.name},
          imgSrc: ${notification.imgSrc}, audioSrc: ${notification.audioSrc}, duration: ${notification.duration},
          visualDelay: ${notification.visualDelay}, audioDelay: ${notification.audioDelay}`);
        queue.enqueue({
          "notification": notification.type,
          "imgSrc": notification.imgSrc,
          "audioSrc": notification.audioSrc,
          "duration": notification.duration,
          "visualDelay": notification.visualDelay,
          "audioDelay": notification.audioDelay
        });
      });
    }
  },
});

