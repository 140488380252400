import Vue from 'vue'
import VueRouter from 'vue-router'

const AnalyticsPage = () => import('./pages/Dashboard/Dashboard.vue');
const Chat = () => import('./pages/Chat/Chat.vue');
const CommandBoard = () => import('./components/Commandboard/Commandboard.vue');
const Commands = () => import('./pages/Commands/Commands.vue');
const Landing = () => import('./pages/Landing/Landing.vue');
const Layout = () => import('./components/Layout/Layout.vue');
const Login = () => import('./pages/Login/Login.vue');
const Graphs = () => import('./pages/Graphs/Graphs.vue');
const Viewers = () => import('./pages/Viewers/Viewers.vue');

Vue.use(VueRouter)

const routes = [
    //{ //path: '/', name: 'dashboard', component: () => import('../views/Dashboard.vue') },
      //path: '/', name: 'dashboard', component: () => import('../App.vue') },
    //path: '/', name: 'dashboard', component: () => import('../pages/Dashboard/Dashboard.vue') },
    {
      path: '/',
      name: 'Login',
      component: Login,
    },
    {
      path: '/landing',
      name: 'Landing',
      component: Landing,
    },
    {
      path: '/dashboard',
      //redirect: '/dashboard',
      name: 'Layout',
      component: Layout,
      children: [
        {
          path: '/dashboard',
          name: 'AnalyticsPage',
          component: AnalyticsPage,
        },
        {
          path: '/viewers',
          name: 'Viewers',
          component: Viewers,
        },
        {
          path: '/commands',
          name: 'Commands',
          component: Commands,
        },
        {
          path: '/chat',
          name: 'Chat',
          component: Chat,
        },
        {
          path: '/commandboard',
          name: 'Commandboard',
          component: CommandBoard,
        },
        {
          path: '/graphs',
          name: 'Graphs',
          component: Graphs,
        },
      ],
      meta: {
        requiresStreamerValidation: true
      }
    },
    { path: '/notifications', name: 'notifications', component: () => import('./components/Notifications.vue') },
    { path: '/goal', name: 'goal', component: () => import('./components/FollowerGoal.vue') },
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    { path: '/about', name: 'about', component: () => import(/* webpackChunkName: "about" */ './views/About.vue') },
]

export default new VueRouter({
  routes
});

