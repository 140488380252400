const state = {
  commands: []
};

const getters = {
  getCommands: state => state.commands
};

const actions = {
  setCommands({ commit }, commands) {
    commit('SET_COMMANDS', commands);
  },
};

const mutations = {
  SET_COMMANDS(state, payload) {
    state.commands = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};