const state = {
  activityStream: []
};

const getters = {
  getActivityStream: state => state.activityStream
};

const actions = {
  setActivityStream({ commit }, activityStream) {
    commit('SET_ACTIVITY_STREAM', activityStream);
  },
};

const mutations = {
  SET_ACTIVITY_STREAM(state, payload) {
    state.activityStream = payload;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};