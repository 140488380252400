



















import axios from 'axios';
import GoogleLogin from 'vue-google-login';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default Vue.extend({
    name: 'Login',
    components: {
        GoogleLogin
    },
    data() {
        return {
            // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
            params: {
                client_id: process.env.GOOGLE_LOGIN_CLIENT_ID
            },
            // only needed if you want to render the button with the google ui
            renderParams: {
                width: 250,
                height: 50,
                longtitle: true,
                center: true
            },
        }
    },
    created() {
        if (this.isAuthorizedUser) {
            this.$router.push('landing');
        }
    },
    methods: {
        getAuthToken(callback) {
            axios.post('https://dev-sfzvc1i6.us.auth0.com/oauth/token', {
                crossDomain: true,
                "client_id": process.env.AUTH0_CLIENT_ID,
                "client_secret": process.env.AUTH0_CLIENT_SECRET,
                "audience": "https://dashboard.zteambot.com",
                "grant_type": "client_credentials"
            }, {
            headers: {
                'Content-Type': 'application/json'
            }
            }).then(res  => {
                callback(null, res)
            }).catch((error) => {
                console.error(error);
                callback(error, null);
            })
        },
        onSuccess(googleUser) {
            // This only gets the user information: id, name, imageUrl and email
            let profile = googleUser.getBasicProfile();
            let email = profile.getEmail();
            this.getAuthToken((err, res) => {
                if (err) {
                    console.error('Unable to get Auth token.')
                }
                if (res) {
                    axios.get('https://dashboard.zteambot.com/users', {
                        headers: {
                            'Authorization': `Bearer ${res.data.access_token}`
                        }
                    }).then(res => {
                        if (res && [200, 201].includes(res.status) && res.data) {
                            let users = res.data
                            if (users) {
                                users.forEach(element => {
                                    if (email == element.username + "@gmail.com") {
                                        Promise.all([
                                            this.$store.dispatch('setAuthorizedUser', true)
                                        ]).then(() => {
                                            this.$router.push('landing');
                                        });
                                    }
                                });
                            }
                        } else {
                            console.log('no users');
                        }
                    })
                }
            })
        },
        onFailure(error) {
            console.error(error);
        }
    },
    computed: {
        ...mapGetters( {
            isAuthorizedUser: 'isAuthorizedUser'
        })
    }
})
